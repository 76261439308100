//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import { Apps, AppLabels } from 'utils/constants';
import PipedriveIcon from 'images/pipedrive-icon.svg';
import ZendeskIcon from 'images/zendesk-sell-icon.svg';
import MailchimpIcon from 'images/mailchimp-icon.svg';
import CopperIcon from 'images/copper-icon.svg';
import HubspotIcon from 'images/hubspot-icon.svg';
import ActivecampaignIcon from 'images/activecampaign-icon.svg';
import CalendlyIcon from 'images/calendly-icon.svg';
import AirtableIcon from 'images/airtable-icon.svg';
import SquarespaceIcon from 'images/squarespace-icon.svg';
import UnbounceIcon from 'images/unbounce-icon.svg';
import WixIcon from 'images/wix-icon.svg';
import ZoomIcon from 'images/zoom-icon.svg';
import GoogleAdsIcon from 'images/google-ads-icon.svg';
import LinkedinIcon from 'images/linkedin-icon.svg';
import WpFormsIcon from 'images/wp-forms-icon.svg';
import GravityIcon from 'images/gravity-forms-icon.svg';
import ElementorFormsIcon from 'images/elementor-forms-icon.svg';
import Cf7Icon from 'images/cf7-icon.svg';
import FacebookIcon from 'images/facebook-icon.svg';
import BrevoIcon from 'images/brevo-icon.svg';
import SalesforceIcon from 'images/salesforce-icon.svg';
import KlaviyoIcon from 'images/klaviyo-icon.svg';
import MailerliteIcon from 'images/mailerlite-icon.svg';
import ConstantcontactIcon from 'images/constantContact-icon.svg';
import GetresponseIcon from 'images/getresponse-icon.svg';
import SendgridIcon from 'images/sendgrid-icon.svg';
import MondayIcon from 'images/monday-icon.svg';
import GoogleSheetsIcon from 'images/google-sheets-icon.svg';

const EVENT_CLICK = 'click';

export default {
	components: {
		PipedriveIcon,
		ZendeskIcon,
		MailchimpIcon,
		HubspotIcon,
		CopperIcon,
		ActivecampaignIcon,
		CalendlyIcon,
		AirtableIcon,
		SquarespaceIcon,
		UnbounceIcon,
		WixIcon,
		ZoomIcon,
		GoogleAdsIcon,
		LinkedinIcon,
		WpFormsIcon,
		GravityIcon,
		ElementorFormsIcon,
		Cf7Icon,
		FacebookIcon,
		BrevoIcon,
		SalesforceIcon,
		KlaviyoIcon,
		MailerliteIcon,
		ConstantcontactIcon,
		GetresponseIcon,
		SendgridIcon,
		MondayIcon,
		GoogleSheetsIcon
	},
	props: {
		app: {
			type: String,
			required: true
		}
	},
	computed: {
		isPipedrive() {
			return this.app === Apps.PIPEDRIVE;
		},
		isZendesk() {
			return this.app === Apps.ZENDESK;
		},
		isSlack() {
			return this.app === Apps.SLACK;
		},
		isActiveCampaign() {
			return this.app === Apps.ACTIVECAMPAIGN;
		},
		isMailchimp() {
			return this.app === Apps.MAILCHIMP;
		},
		isHubspot() {
			return this.app === Apps.HUBSPOT;
		},
		isCopper() {
			return this.app === Apps.COPPER;
		},
		isCalendly() {
			return this.app === Apps.CALENDLY;
		},
		isAirtable() {
			return this.app === Apps.AIRTABLE;
		},
		isSquarespace() {
			return this.app === Apps.SQUARESPACE;
		},
		isUnbounce() {
			return this.app === Apps.UNBOUNCE;
		},
		isWix() {
			return this.app === Apps.WIX;
		},
		isZoom() {
			return this.app === Apps.ZOOM;
		},
		isGoogleAds() {
			return this.app === Apps.GOOGLE_ADS;
		},
		isLinkedin() {
			return this.app === Apps.LINKEDIN;
		},
		isWpForms() {
			return this.app === Apps.WP_FORMS;
		},
		isGravity() {
			return this.app === Apps.GRAVITY;
		},
		isElementorForms() {
			return this.app === Apps.ELEMENTOR_FORMS;
		},
		isCf7() {
			return this.app === Apps.CF7;
		},
		isFacebook() {
			return this.app === Apps.FACEBOOK;
		},
		isSendinblue() {
			return this.app === Apps.SENDINBLUE;
		},
		isbrevo() {
			return this.app === Apps.BREVO;
		},
		isSalesforce() {
			return this.app === Apps.SALESFORCE;
		},
		isLinkedinMatchedAudiences() {
			return this.app === Apps.LINKEDIN_MATCHED_AUDIENCES;
		},
		isFacebookAds() {
			return this.app === Apps.FACEBOOK_ADS;
		},
		isGoogleAdsCustomerList() {
			return this.app === Apps.GOOGLE_ADS_CUSTOMER_LIST;
		},
		isHubspotMarketing() {
			return this.app === Apps.HUBSPOT_MARKETING;
		},
		isKlaviyo() {
			return this.app === Apps.KLAVIYO;
		},
		isMailerlite() {
			return this.app === Apps.MAILERLITE;
		},
		isConstantContact() {
			return this.app === Apps.CONSTANT_CONTACT;
		},
		isGetResponse() {
			return this.app === Apps.GETRESPONSE;
		},
		isSendGrid() {
			return this.app === Apps.SENDGRID;
		},
		isMonday() {
			return this.app === Apps.MONDAY;
		},
		isGoogleSheets() {
			return this.app === Apps.GOOGLE_SHEETS;
		},
		appName() {
			return AppLabels[this.app];
		}
	},
	methods: {
		onClick() {
			this.$emit(EVENT_CLICK, this.app);
		}
	}
};
