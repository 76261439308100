import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { IntegrationsException } from '../helpers';

/**
 * @param {String} token JWT token.
 * @param {String} integration
 * @param {String=} segmentGroupId
 * @return {Promise<{Object}>}
 */
export const httpGet = async (token, integration, segmentGroupId) => {
	const url = new URL(endpoints.integrationSegments(integration));

	if (segmentGroupId) {
		url.searchParams.set('segmentGroupId', segmentGroupId);
	}

	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new IntegrationsException(response));
	}

	return response.data;
};
