import { httpPost } from './httpPost';
import { httpDelete } from './httpDelete';
import { segmentGroups } from './segment-groups';
import { segments } from './segments';

export const integrations = {
	post: httpPost,
	delete: httpDelete,
	segmentGroups,
	segments
};
