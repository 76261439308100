//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//


import { createNamespacedHelpers } from 'vuex';

import { BANNER_TRIAL_ENDING_HIDDEN } from 'store/modules/user/mutationTypes';
import BannerTrialEnding from './BannerTrialEnding.vue';
import BannerTrialEnded from './BannerTrialEnded.vue';
import BannerPaymentFailed from './BannerPaymentFailed.vue';
import BannerEmailUsageExceeded from './BannerEmailUsageExceeded.vue';
import BannerLoggedInAsUser from './BannerLoggedInAsUser.vue';
import BannerActiveContactsLimitExceeded from './BannerActiveContactsLimitExceeded.vue';
import BannerActiveContactsLimitNear from './BannerActiveContactsLimitNear.vue';

const userHelpers = createNamespacedHelpers('user');
const accountBillingHelpers = createNamespacedHelpers('account/billing');

export default {
	components: {
		BannerTrialEnding,
		BannerTrialEnded,
		BannerPaymentFailed,
		BannerEmailUsageExceeded,
		BannerLoggedInAsUser,
		BannerActiveContactsLimitExceeded,
		BannerActiveContactsLimitNear
	},

	computed: {
		...userHelpers.mapGetters([
			'areBillingNotificationsEnabled',
			'areActiveContactPricingPlansEnabled'
		]),

		...userHelpers.mapState([
			'profile',
			'usage',
			'isBannerTrialEndingHidden'
		]),

		isBannerVisible() {
			return [
				this.isBannerTrialEndingVisible,
				this.isBannerTrialEndedVisible,
				this.isBannerEmailUsageExceededVisible,
				this.isBannerPaymentFailedVisible,
				this.isBannerLoggedInAsUserVisible,
				this.isBannerActiveContactsLimitExceededVisible,
				this.isBannerActiveContactsLimitNearVisible
			].some(Boolean);
		},

		isBannerTrialEndingVisible() {
			return (
				this.areBillingNotificationsEnabled
				&& !this.isTrialEnded
				&& this.isTrialEnding
				&& !this.isBannerTrialEndingHidden
			);
		},

		isBannerTrialEndedVisible() {
			return this.areBillingNotificationsEnabled
				&& this.isTrialEnded;
		},

		outfunnelEmailsPercentage() {
			const outfunnelEmailsCount = this.usage.outfunnel.emails.count;
			const outfunnelEmailsLimit = this.usage.outfunnel.emails.limit;

			return (outfunnelEmailsCount / outfunnelEmailsLimit) * 100;
		},

		isBannerEmailUsageExceededVisible() {
			return !this.isActiveContactsPlan && this.outfunnelEmailsPercentage > 100;
		},

		isBannerPaymentFailedVisible() {
			return this.areBillingNotificationsEnabled
				&& this.isPaymentFailed;
		},

		isBannerLoggedInAsUserVisible() {
			return this.$store.state.isLoggedInAsUser;
		},

		isBannerActiveContactsLimitExceededVisible() {
			return this.isActiveContactsPlan
				&& this.activeContactsLimitUsagePercentage > 100;
		},

		isBannerActiveContactsLimitNearVisible() {
			const NEAR_LIMIT = 80;

			return this.isActiveContactsPlan
				&& (this.activeContactsLimitUsagePercentage >= NEAR_LIMIT && this.activeContactsLimitUsagePercentage < 100);
		},

		...userHelpers.mapGetters([
			'isTrialEnding',
			'isPaymentFailed'
		]),

		...accountBillingHelpers.mapGetters([
			'isTrialEnded',
			'trialDaysRemaining',
			'currentPlan',
			'isActiveContactsPlan'
		]),

		activeContactsLimit() {
			return this.currentPlan?.active_contacts;
		},

		activeContactsLimitUsagePercentage() {
			if (!this.activeContactsLimit) {
				return 0;
			}

			const currentUsage = this?.usage?.activeContacts?.activeContactsTotalCount || 0;

			return (currentUsage / this.activeContactsLimit) * 100;
		}

	},

	methods: {
		...userHelpers.mapMutations([
			BANNER_TRIAL_ENDING_HIDDEN
		])
	}
};

