import { request } from 'utils/request';
import { endpoints } from 'api/endpoints';
import { IntegrationsException } from '../helpers';

/**
 * @param {String} token JWT token.
 * @param {String} integration
 * @param {String=} searchQuery
 * @return {Promise<{Object}>}
 */
export const httpGet = async (token, integration, searchQuery) => {
	const url = new URL(endpoints.segmentGroups(integration));

	if (searchQuery) {
		url.searchParams.set('search', searchQuery);
	}

	const response = await request.get(url, token);

	if (!response.success) {
		return Promise.reject(new IntegrationsException(response));
	}

	return response.data;
};
