export default {
	HOME: 'home',
	LOGIN: 'login',
	OAUTH: 'oauth',
	REGISTER: 'register',
	RESET_PASSWORD: 'reset-password',
	RESET_PASSWORD_CREATE: 'reset-password/create',
	PASSWORDLESS_LOGIN: 'passwordless-login',
	PASSWORDLESS_LOGIN_VERIFY: 'passwordless-login/verify',
	EMAIL_VERIFICATION: 'email-verification',
	INSTALL_WIX: 'install-wix',
	CAMPAIGNS: 'campaigns',
	CAMPAIGNS_DASHBOARD: 'campaigns/dashboard',
	CAMPAIGNS_SINGLE_EMAIL: 'campaigns/single-email',
	CAMPAIGNS_SINGLE_EMAIL_CREATE: 'campaigns/single-email/create',
	CAMPAIGNS_SINGLE_EMAIL_BASICS: 'campaigns/single-email/basics',
	CAMPAIGNS_SINGLE_EMAIL_COMPOSE: 'campaigns/single-email/compose',
	CAMPAIGNS_SINGLE_EMAIL_REVIEW: 'campaigns/single-email/review',
	CAMPAIGNS_SINGLE_EMAIL_REPORT: 'campaigns/single-email/report',
	CAMPAIGNS_SINGLE_EMAIL_PREVIEW: 'campaigns/single-email/preview',
	CAMPAIGNS_SEQUENCE: 'campaigns/sequence',
	CAMPAIGNS_SEQUENCE_CREATE: 'campaigns/sequence/create',
	CAMPAIGNS_SEQUENCE_EMAILS: 'campaigns/sequence/emails',
	CAMPAIGNS_SEQUENCE_REPORT: 'campaigns/sequence/report',
	CAMPAIGNS_SEQUENCE_EMAILS_CREATE: 'campaigns/sequence/emails/create',
	CAMPAIGNS_SEQUENCE_EMAILS_EDIT: 'campaigns/sequence/emails/edit',
	CAMPAIGNS_SEQUENCE_EMAILS_REPORT: 'campaigns/sequence/emails/report',
	CAMPAIGNS_SEQUENCE_EMAILS_PREVIEW: 'campaigns/sequence/emails/preview',
	CAMPAIGNS_SEQUENCE_SETTINGS: 'campaigns/sequence/settings',
	CONNECTIONS: 'connections',
	CONNECTIONS_WELCOME: 'connections/welcome',
	CONNECTIONS_CREATE: 'connections/create',
	CONNECTIONS_CREATE_SELECT_TYPE: 'connections/create/select-type',
	CONNECTIONS_EDIT: 'connections/edit',
	CONNECTIONS_EDIT_CONTACT_SYNC: 'connections/edit/contact-sync',
	CONNECTIONS_EDIT_CONTACT_SYNC_SELECT_DIRECTION: 'connections/edit/contact-sync/select-direction',
	CONNECTIONS_EDIT_ENGAGEMENT_SYNC: 'connections/edit/engagement-sync',
	CONNECTIONS_EDIT_ENGAGEMENT_LIMIT: 'connections/edit/engagement-sync-limit',
	CONNECTIONS_EDIT_STATUS: 'connections/edit/status',
	CONNECTIONS_EDIT_FIELD_MAPPING: 'connections/edit/field-mapping',
	CONNECTIONS_EDIT_CONTACT_SYNC_DIRECTION: 'connections/edit/contact-sync-direction',
	CONNECTIONS_EDIT_CONTACT_SYNC_SETTINGS: 'connections/edit/contact-sync-settings',
	CONNECTIONS_EDIT_ENGAGEMENT_SETTINGS: 'connections/edit/engagement-settings',
	CONNECTIONS_STATUS: 'connections/status',
	CONNECTIONS_CONTACT_SYNC_STATUS: 'connections/contact-sync-status',
	CONNECTIONS_FORM_STATUS: 'connections/form-status',
	CONTACTS: 'contacts',
	CONTACTS_PROFILE: 'contacts/profile',
	DASHBOARD: 'dashboard',
	SEGMENTS_CREATE: 'segments/create',
	SEGMENTS_EDIT: 'segments/edit',
	WEB_TRACKING_CHANNEL_REPORT_NEW: 'web-tracking/channel-report-new',
	WEB_TRACKING_HOME: 'web-tracking/home',
	WEB_TRACKING_IDENTIFIED_VISITORS: 'web-tracking/identified-visitors',
	WEB_TRACKING_LINKS: 'web-tracking/create-tracking-links',
	WEB_TRACKING_WORKFLOWS: 'web-tracking/workflows',
	WEB_TRACKING_SETUP: 'web-tracking/setup',
	LEAD_SCORING: 'lead-scoring',
	LEAD_SCORING_EDIT: 'lead-scoring/edit',
	LEAD_SCORING_DASHBOARD: '/lead-scoring/edit/dashboard',
	LEAD_SCORING_WORKFLOWS: '/lead-scoring/edit/workflows',
	ACCOUNT_USAGE: 'account/usage',
	ACCOUNT_PROFILE: 'account/profile',
	ACCOUNT_BILLING: 'account/billing',
	ACCOUNT_BILLING_PAYMENTS: 'account/billing/payments',
	ACCOUNT_FIELDS: 'account/fields',
	EMAIL_SETTINGS: 'campaigns/email-settings',
	EMAIL_SETTINGS_SENDING_SCHEDULE: 'campaigns/email-settings/sending-schedule',
	EMAIL_SETTINGS_REPLY_TRACKING: 'campaigns/email-settings/reply-tracking',
	EMAIL_SETTINGS_DOMAINS: 'campaigns/email-settings/domains',
	EMAIL_SETTINGS_DOMAINS_EDIT: 'campaigns/email-settings/domains/edit',
	EMAIL_SETTINGS_FOOTER: 'campaigns/email-settings/footer',
	EMAIL_SETTINGS_EMAIL_HEALTH: 'campaigns/email-settings/email-health',
	EMAIL_SETTINGS_ENGAGEMENT_RECORDING: 'campaigns/email-settings/engagement-recording',
	ERROR_404: '404',
	ERROR_500: '500',
	INTEGRATIONS: 'integrations'
};
