//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

/**
 * @constant {String}
 */
const EVENT_CLOSE = 'close';
/**
 * @constant {String}
 */
const EVENT_NAVIGATE_CONTINUE = 'navigate:continue';

export default {
	props: {
		engagementEventSource: {
			type: String,
			required: true
		},
		isVisible: {
			type: Boolean,
			required: true
		}
	},

	data: () => ({
		isRecentEngagementSyncEnabled: true
	}),

	computed: {
		learnMoreLink() {
			return 'https://support.outfunnel.com/en/articles/6493239-sync-historical-marketing-engagements';
		},

		isKlaviyoEngagementSource() {
			return this.engagementEventSource === 'klaviyo';
		},

		popupBody() {
			if (this.isKlaviyoEngagementSource) {
				return `After activating the connection Outfunnel will start recording new events from the selected source.
			You can also choose to sync last 30-day email events when first activating the connection.
			Note that non-email events won't sync retrospectively.`;
			}

			return `After activating the connection Outfunnel will start recording new events from the selected source.
			You can also choose to sync last 30-day events when first activating the connection.`;
		},

		thirtyDayRadioLabel() {
			if (this.isKlaviyoEngagementSource) {
				return 'Sync last 30-day email events and new events';
			}

			return 'Sync last 30-day events and new events';
		}
	},

	methods: {
		close() {
			this.$emit(EVENT_CLOSE);
		},

		navigateContinue() {
			this.$emit(EVENT_NAVIGATE_CONTINUE, this.isRecentEngagementSyncEnabled);
		},

		onChangeRecentEngagementSync(isEnabled) {
			this.isRecentEngagementSyncEnabled = isEnabled;
		}
	}
};

