import logger from 'utils/logger';
import { getIsLoggedInAsUser } from 'utils/storage';

/**
 * @constant {String[]}
 */
const SEGMENT_METHODS = [
	'trackSubmit',
	'trackClick',
	'trackLink',
	'trackForm',
	'pageview',
	'identify',
	'reset',
	'group',
	'track',
	'ready',
	'alias',
	'debug',
	'page',
	'once',
	'off',
	'on',
	'addSourceMiddleware',
	'addIntegrationMiddleware',
	'setAnonymousId',
	'addDestinationMiddleware'
];

/**
 * Enum for analytics event names.
 * @constant {{
 * INTEGRATION_ADDED: String,
 * INTEGRATION_REMOVED: String,
 * SELECT_FEED_PAGE: String,
 * REGISTER_PAGE_VIEWED: String,
 * CONNECTION_CREATION_INITIATED: String,
 * DASHBOARD_CTA_CLICKED: String,
 * WEB_TRACKING_WORKFLOW_CHANGED: String,
 * MANUAL_SYNC_INITIATED: String,
 * STATUS_CONTACT_PROFILE_OPENED: String
 * }}
 */
const EventNames = Object.freeze({
	INTEGRATION_ADDED: 'Integration added',
	INTEGRATION_REMOVED: 'Integration removed',
	SELECT_FEED_PAGE: 'Select feed page',
	REGISTER_PAGE_VIEWED: 'Register page viewed',
	CONNECTION_CREATION_INITIATED: 'Connection creation initiated',
	DASHBOARD_CTA_CLICKED: 'Dashboard CTA Clicked',
	WEB_TRACKING_WORKFLOW_CHANGED: 'Web Tracking Workflow Changed',
	TRACKING_LINK_GENERATED: 'Tracking link generated',
	MANUAL_SYNC_INITIATED: 'Manual Sync Initiated',
	STATUS_CONTACT_PROFILE_OPENED: 'Status Contact Profile Opened'
});

/**
 * Set up and load Segment Analytics script.
 */
const loadAnalytics = () => {
	const analytics = window.analytics || [];

	window.analytics = analytics;

	if (analytics.initialize) {
		return;
	}

	if (analytics.invoked) {
		logger.error('segment snippet included twice');
	} else {
		analytics.invoked = true;
		analytics.methods = SEGMENT_METHODS;
	}

	/**
	 * Segment internal factory function.
	 * @param {*} event
	 */
	analytics.factory = event => (...args) => { // eslint-disable-line fp/no-rest-parameters
		const argsCopy = Array.prototype.slice.call(args);

		argsCopy.unshift(event);
		analytics.push(argsCopy);

		return analytics;
	};

	analytics.methods.forEach(method => {
		analytics[method] = analytics.factory(method);
	});

	/**
	 * Load Segment Analytics script.
	 * @param {String} segmentId
	 * @param {Object} loadOptions
	 */
	analytics.load = (segmentId, loadOptions) => {
		const segmentScript = document.createElement('script');

		segmentScript.type = 'text/javascript';
		segmentScript.async = true;
		segmentScript.src = `https://cdn.segment.com/analytics.js/v1/${segmentId}/analytics.min.js`;

		const firstScript = document.getElementsByTagName('script')[0];

		firstScript.parentNode.insertBefore(segmentScript, firstScript);

		// eslint-disable-next-line no-underscore-dangle
		analytics._loadOptions = loadOptions;
	};

	analytics.SNIPPET_VERSION = '4.1.0';
	analytics.load(process.env.SEGMENT_ID);
};

/**
 * Identify the user by UserId and traits.
 * @param {Object} params
 * @param {String} params.userId
 * @param {String} params.traits
 */
const identify = ({ userId, traits }) => {
	if (getIsLoggedInAsUser()) {
		return;
	}

	window.analytics.identify(userId, traits);
	logger.debug(`Identify user: ${userId}`, traits);
};

/**
 * Track an event.
 * @param {Object} params
 * @param {String} params.event Name of the analytics event.
 * @param {Object=} params.properties Additional properties for the event.
 */
const track = ({ event, properties }) => {
	if (getIsLoggedInAsUser()) {
		return;
	}

	window.analytics.track(event, properties);
	logger.debug(`Track event: ${event}`, properties);
};

/**
 * Track pageview.
 * @param {Object} params
 * @param {String} params.routeName
 */
const page = ({ routeName }) => {
	if (getIsLoggedInAsUser()) {
		return;
	}

	window.analytics.page('App', { routeName });
};

/**
 * @return {void}
 */
const reset = () => {
	window.analytics.reset();
};

export {
	EventNames,
	identify,
	loadAnalytics,
	track,
	page,
	reset
};
